import React, { useEffect, useContext, useState } from 'react';
import ReactGA from 'react-ga4';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';

import Components from 'components/CmsComponents/';
import { DSLandingFeaturedMarketing } from 'components/CmsComponents/DSLandingFeaturedMarketing';

import { DoctorContext } from 'context/doctor';
import { HeaderContext } from 'context/header';

import { createDoctorData } from 'utils/createDoctorData';

const EspecialidadesMedicas = ({
  data: {
    gcms: { especialidadesMedics, specialty, doctors },
    doctorsApi,
  },
}) => {
  const [isDesktop, setDesktop] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const page = especialidadesMedics?.[0];
  const DSLandingFeaturedMarketingContent = page?.dSLandingFeaturedMarketing;
  const components = page?.components ?? [];
  const specialtyDoctors = page?.specialty?.doctor || specialty?.doctor;
  const apiDoctors = doctorsApi?.doctors;

  const { setRenderList, setDoctorsList, setDoctorsOncology } = useContext(
    DoctorContext
  );

  if (specialtyDoctors) {
    const formattedDoctors = specialtyDoctors
      .map(doctor =>
        createDoctorData({
          doctorCMS: doctor,
          doctorsAPI: apiDoctors,
        })
      )
      ?.filter(e => e)
      .sort(
        (a, b) =>
          (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() && 1) || -1
      )
      .sort((a, b) => b.employee - a.employee);

    useEffect(() => {
      setDoctorsOncology(formattedDoctors);
      setDoctorsList(formattedDoctors);
      setRenderList('all');
    }, []);
  }

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('0rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    // Inicializando o Google Analytics
    if (Boolean(page?.tagGa)) {
      ReactGA.initialize(page?.tagGa);
      ReactGA.send('pageview');
    }
  }, []);

  return (
    <Layout>
      <SEO dataSeo={dataSeo} />
      {DSLandingFeaturedMarketingContent && (
        <DSLandingFeaturedMarketing
          content={DSLandingFeaturedMarketingContent}
        />
      )}
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
    </Layout>
  );
};

export const EspecialidadesMedicasPageQuery = graphql`
  query EspecialidadesMedicasPageQuery($id: ID, $slug: String) {
    doctorsApi {
      doctors {
        id
        name
        gender
        crm
        email
        phone
        phonecommercial
        specialty
        employee
        estab
        state
      }
    }
    gcms {
      doctors(first: 1000) {
        active
        id
        id_api
      }
      specialty(locales: [pt_BR, en], where: { slug: $slug }) {
        doctor(first: 1000) {
          id
          id_api
          active
          assignment
          name
          crm
          photo {
            handle
            url
            height
            width
          }
          phonecommercial
          state
          employee
          specialties {
            id
            name
          }
        }
      }
      especialidadesMedics(locales: [pt_BR, en], where: { id: $id }) {
        id
        title
        slug
        region
        tagGa
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        dSLandingFeaturedMarketing(locales: [pt_BR, en]) {
          id
          type
          universalLink
          activeBreaknews
          breaknews {
            id
            name
            type
            singletexts
            markdown
          }
          backgroundColor {
            hex
          }
          images {
            handle
            width
            height
            url
          }
          title
          fragments {
            id
            name
            type
            markdown
          }
        }
        components(locales: [pt_BR, en]) {
          __typename
          ... on GraphCMS_SimpleContent {
            id
            name
            type
            title
            fragments {
              id
              name
              type
              markdown
              datafragment
              singletexts
              assetpicker {
                handle
                width
                height
                url
              }
            }
          }
          ... on GraphCMS_HighlightContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_BannerSpecialtyContent {
            id
            name
            type
            backgroundColor {
              hex
            }
            backgroundColorInitialAlias: backgroundColorInitial {
              hex
            }
            backgroundColorFinalAlias: backgroundColorFinal {
              hex
            }
            title
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_VideoGalleryContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            description
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_ListPostContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
          ... on GraphCMS_DoctorAndSpecialtyContent {
            id
            name
            type
            title
            subtitle
            estado
            images {
              handle
              width
              height
              url
            }
            specialties {
              id
              name
              slug
              customUrl
              unity
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_DoctorsSearch {
            id
            name
            location
            noSearch
          }
          ... on GraphCMS_OurUnits {
            id
            isASCO
            name
            customFragment {
              id
              name
              type
              markdown
              datafragment
              singletexts
              localizations {
                markdown
              }
            }
          }
          ... on GraphCMS_OurSpecialties {
            id
            name
            title
            customFragment {
              id
              name
              type
              markdown
              datafragment
              singletexts
              localizations {
                markdown
              }
            }
          }
          ... on GraphCMS_ListQAContent {
            id
            name
            title
            subTitle
            type
            isDesignSystem

            anchorTo
            faqLink
            backgroundColor {
              hex
            }
            qaData {
              ... on GraphCMS_CustomFragment {
                id
                name
                markdown
                singletexts
              }
              ... on GraphCMS_Qa {
                id
                autor
                pergunta
                titulo
                doctors(first: 1000) {
                  id
                  id_api
                  active
                  assignment
                  name
                }
              }
            }
          }
          ... on GraphCMS_HighlightFormLeadContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            referenceToSalesforce
            images {
              handle
              width
              height
              url
            }
            documentsForDownload {
              handle
              width
              height
              url
            }
            footnote
          }
        }
        specialty {
          doctor {
            active
            id
            assignment
            crm
            employee
            id_api
            name
            phonecommercial
            state
            photo {
              handle
              height
              url
              width
            }
            specialties {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default EspecialidadesMedicas;
